<template>
  <div class="inspection">

    <div v-if="rejectReason" class="reject-reason">{{ rejectReason }}</div>

    <!-- <div class="otp-desc">
      <span>
        {{ $t('needOTPDesc', {mobileNo: mobileNo}) }}
      </span>
    </div> -->

    <FuFormMin
      v-if="!loading"
      ref="inspectionForm"
      v-model="formData"
      :schema="formJSON"
      :lang="lang"
      :disabled="!canEdit"
    />

    <div v-if="canEdit" class="footer fu-fixed">
      <Button
        class="th-next-btn"
        type="danger"
        color="#d9000b"
        :disabled="!!loading"
        @click="onSubmit"
      >
        {{ $t('submit') }}
      </Button>
    </div>
  </div>
</template>

<script>
import { Button, Dialog } from 'vant'
import FuFormMin from '@/components/FuFormMin'
import { toastLoading } from '@/utils/index'
import dao from '../../utils/dao'
import { appendInspection } from './api'
import { getInspectionInfo } from './api'
import { isDesktop } from '../../utils/dispatch'

export default {
  name: 'Inspection',
  components: {
    FuFormMin,
    Button
  },
  data() {
    return {
      loading: null,
      canEdit: false,
      formData: {},
      formJSON: {},
      checkSchema: {},
      inspectionInfo: {}
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.lang || 'vn'
    },
    quoteNo() {
      return this.$route.query.quoteNo
    },
    mobileNo() {
      return '+84 ' + dao.get('inspectionInfo').shareInspectionInfo?.inspectionPolicyHolderInfo?.policyHolderMobile || ''
    },
    // 通过路由是否传有投保单号来区别是否需要提交给后台
    hasSubmit() {
      return !this.quoteNo
    },
    rejectReason() {
      if (this.inspectionInfo.currentInspectionStatus == 3 && this.inspectionInfo.underwritingPlatformReason) {
        return this.inspectionInfo.underwritingPlatformReason ?? ''
      } else {
        return ''
      }
    }
  },
  async activated() {
    if (window.innerWidth > 768) {
      this.$toast(this.$t('invalidPlatform'))
      const delay = ms => new Promise(res => setTimeout(res, ms))
      await delay(1000)
      this.$router.go(-1)
    } else {
      this.init()
    }
  },
  methods: {
    async init() {
      this.inspectionInfo = dao.get('inspectionInfo')
      const token = dao.get('inspectionToken')

      const params = {
        slipUid: this.inspectionInfo?.shareInspectionInfo?.orderSlipInspectionDTO?.slipUid
      }
      // inspection资料状态 1:required 2:submitted 3:rejected 4:finished
      if (![1].includes(this.inspectionInfo.currentInspectionStatus)) {
        this.loading = toastLoading()
        const inspectionInfoView = await getInspectionInfo(params, token)
        const inspectionData = JSON.parse(inspectionInfoView.carInspectionData)
        this.formData = inspectionData
        this.loading = null
      }

      this.$nextTick(() => {
        this.$refs.inspectionForm && this.$refs.inspectionForm.resetValidate()
      })

      this.getInspectionInfo()
      this.getCanEdit()
    },
    getCanEdit() {
      const inspectionInfo = dao.get('inspectionInfo')
      this.canEdit = [1, 3].includes(inspectionInfo.currentInspectionStatus) && !isDesktop
    },
    async getInspectionInfo() {
      const inspectionInfo = dao.get('inspectionInfo')

      this.formJSON = JSON.parse(inspectionInfo.inspectionTemplateOutDTO.displaySchema)
      this.checkSchema = inspectionInfo.inspectionTemplateOutDTO.checkSchema
    },
    // 提交数据
    onSubmit() {
      this.$refs.inspectionForm.validate().then(res => {
        if (this.hasSubmit) {
          this.handleSubmit()
          return
        }
      }).catch(error => {
        console.log('----fail----')
        console.log(error)
      })
    },

    // 提交操作
    handleSubmit() {
      Dialog.confirm({
        message: this.getDialogMessage(),
        theme: 'round-button',
        confirmButtonText: this.$t('no'),
        // confirmButtonColor: '#C82A21',

        showCancelButton: true,
        cancelButtonText: this.$t('yes'),
        // cancelButtonColor: '#fff',
        beforeClose: this.beforeClose,
        className: 'inspection-dialog'
      })
    },
    async beforeClose(action, done) {
      const inspectionInfo = dao.get('inspectionInfo')

      // 取消 这个设计稿是把确认放在左边
      // 所以组件的confirm 相当于取消
      if (action === 'confirm') {
        done()
        return
      }

      // 暂时关闭inspection OTP

      // dao.set('inspectionAppendForm', {
      //   carInspectionData: JSON.stringify(this.formData),
      //   carInspectionSchema: this.checkSchema,
      //   slipUid: inspectionInfo.shareInspectionInfo.orderSlipInspectionDTO.slipUid
      // })
      // done()
      // this.$router.push(`/inspection/verification`)

      const token = dao.get('inspectionToken')

      const params = {
        carInspectionData: JSON.stringify(this.formData),
        carInspectionSchema: this.checkSchema,
        slipUid: inspectionInfo.shareInspectionInfo.orderSlipInspectionDTO.slipUid
      }

      const res = await appendInspection(params, token)

      done()

      if (!res || res.code !== 200) {
        this.$toast(res.message)
      } else {
        this.canEdit = false
        this.$router.push('/inspection/orderDetail/?token=' + token)
      }
      // 返回上一个页面
    },
    getDialogMessage() {
      return `
        <i class="van-icon van-icon-warning-o" style="margin-bottom: 25px; color: #C82A21; font-size: 65px;"></i>
        <p class="tit" style="font-weight: 900; font-size: 14px; color: #C82A21;">${this.$t('confirmSubmit')}</p>
        <p style="font-size: 12px">${this.$t('inspectionSubmitTip')}</p>
      `
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 40px;
  background: #fff;
  box-shadow: 0px -2.5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  .van-button {
    // background: #C82A21;
    box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    .van-button__text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      // color: #FFFFFF;
    }
  }

  .van-button + .van-button {
    margin-left: 10px;
  }
}
.inspection {
  padding-bottom: 50px;
  background-color: #fbfbfb;

  .reject-reason {
    font-size: 15px;
    padding: 20px;
    margin: 10px 20px 0px 20px;
    background-color: #FFB8B8;
    color: #CC0404;
    border-radius: 10px;
  }

  .otp-desc {
    background: #D9D9D9;
    font-size: 12px;
    margin: 15px;
    padding: 10px;
  }

}
</style>

<style lang="scss">
.inspection-dialog {
  .van-dialog__footer {
    justify-content: space-between;
  }
  .van-dialog__message {
    white-space: pre-line;
  }
  .van-dialog__cancel,
  .van-dialog__confirm {
    width: 130px;
    border: 1.5px solid #C82A21;
    box-shadow: 0px .35px 1.4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    background: none;
  }
  .van-dialog__cancel {
    background-color: #FFFFFF;
    color: #C82A21;
  }
  .van-dialog__confirm {
    margin-left: 10px;
    background-color: #C82A21;
  }
}
</style>
